<template>
	<div class="container">
		<top-header></top-header>
		<div class="standard width-1300">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item to="/datalake">全球产品大数据湖</el-breadcrumb-item>
			  <el-breadcrumb-item>标准化目录&文献&专利</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="top">
				<div class="title">
          <span class="item active" @click="gourl('/standard')">
							标准化目录
          </span>
          /
          <span class="item " @click="gourl('/patent')">
							专利
          </span>
          /
          <span class="item " @click="gourl('/literature')">
							文献
          </span>
        </div>
				<div class="search">
					<input type="" name="" id="" value="" placeholder="请输入文件名搜索" v-model="inputValue"/>
					<div class="btn" @click="searchBtn">搜索</div>
				</div>
			</div>
			<div class="standard-table">
				<el-table :data="tableData.data" style="width: 100%" stripe
					:header-cell-style="{'background-color':'#FAFAFA'}">
					<el-table-column prop="num" label="编号" width="180" align="center">
					</el-table-column>
					<el-table-column prop="name" label="文件名" width="350">
					</el-table-column>
          <el-table-column prop="type_id" label="类型" width="180">
					</el-table-column>
					<el-table-column prop="size" label="大小/MB" width="180">
					</el-table-column>
					<el-table-column prop="created_at" label="上传日期" width="180">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="text" size="mini" @click="handleEdit(scope.$index, scope.row)">预览
							</el-button>
              <el-button type="text" size="mini" @click="handleDelete(scope.$index, scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="standard-table-page">
					<el-pagination background layout="prev, pager, next" :pager-count="5" :total="tableData.total"
						@size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[5, 10, 20, 30,100]">
					</el-pagination>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter
		},
    inject: ["reload"],
		data() {
			return {
				page:1,
				num:10,
				tableData: [],
				inputValue:''
			}
		},
		created() {
			this.getList(this.$route.query.type_id);
		},
		methods: {
			searchBtn(){
				this.getList(this.$route.query.type_id);
			},
			getList(type_id){
				this.$get('front/standard/get_list',{
					page:this.page,
					num:this.num,
          type_id:type_id,
					name:this.inputValue
				}).then(res =>{
					this.tableData = res.data.data;
          var page = parseInt(this.page);
          var num = parseInt(this.num);
          for(var i in this.tableData.data){
            var j = parseInt(i) + 1;
            this.tableData.data[i]['num'] = (page-1) * num+j;
            this.tableData.data[i]['size'] = parseFloat(this.tableData.data[i]['size']).toFixed(2);
          }
				})
			},
			handleEdit(index,row){
				//console.log(index,row);
				// if(document.documentElement.clientWidth < 768){
				// 	alert('手机暂不支持预览！');
				// }else{
					window.open(`https://api.naipu.cloud/${row.url}`);  //线上目录
				//}
			},
      handleDelete(index, row) {
				console.log(index, row);
				this.$confirm('此操作将永久删除此标准, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('front/del/standard', {
						id: row.id
					}).then(res => {
						console.log(res);
						if (res.data.status == 200) {
							this.$notify({
								title: '提示',
								message: '操作成功！',
								type: 'success'
							});
							this.reload();
						} else {
							this.$notify.error({
								title: '提示',
								message: res.data.message
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

			},
			handleSizeChange(val) {
				//console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.page = val;
				this.getList(this.$route.query.type_id);
			},
      gourl(url) {
				this.$router.push(url)
			},
		}
	}
</script>

<style lang="scss">
	.standard {
		margin-top: 60px;
		font-size: 16px;
		padding-top: 20px;
		box-sizing: border-box;
		min-height: 89vh;
		.standard-table {
			background-color: #fff;
			box-sizing: border-box;
			padding-bottom: 1px;
			margin-bottom: 55px;
		}

		.standard-table-page {
			text-align: center;
			margin: 30px 0;
		}

		.top {
			margin: 30px 0;
			display: flex;
			justify-content: space-between;
			@media screen and(min-width:320px) and(max-width:767px) {
				display: block;
			}
			.title {
				font-size: 26px;
				letter-spacing: 2px;
        color:#C0C4CC;
				@media screen and (min-width:320px) and (max-width:767px) {
					display: none;
				}
        .item {
					cursor: pointer;
					&:hover {
						color: #00337D;
					}
				}
        .active {
					color: #00337D;
				}
			}

			.search {
				input {
					border: 0;
					outline: none;
					height: 40px;
					padding: 10px;
					box-sizing: border-box;
					color: #999;
					width: 380px;
					vertical-align: middle;
					@media screen and(min-width:320px) and(max-width:767px) {
						width: 75%;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
				}

				.btn {
					color: #fff;
					background-color: #00337D;
					height: 40px;
					line-height: 40px;
					padding: 0 20px;
					display: inline-block;
					vertical-align: middle;
					box-sizing: border-box;
					cursor: pointer;
					@media screen and(min-width:320px) and(max-width:767px) {
						width: 25%;
						text-align: center;
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
					}
				}
			}
		}
	}
</style>
